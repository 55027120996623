import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import SEO from "../components/header/seo"
import { graphql } from "gatsby"
import PageIntro from "../components/fragment/pageIntro"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("sm")]: {
      padding: "50px 50px 100px",
    },
  },
}))

const NotFoundPage = props => {
  const { data } = props
  const classes = useStyles()

  return (
    <Layout location={props.location}>
      <div className={classes.container}>
        <SEO title="404: Not found" />
        <PageIntro file={data.file} data={data.markdownRemark} />
      </div>
    </Layout>
  )
}

export default injectIntl(NotFoundPage)

export const query = graphql`
  query($language: String) {
    file(relativePath: { eq: "undraw_not_found.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(
      frontmatter: { lang: { eq: $language }, slug: { eq: "notfound" } }
    ) {
      frontmatter {
        slug
        title
        subtitle
      }
      html
    }
  }
`
